import React from 'react';
import {authRole} from '../shared/constants/AppConst';
import {
  AccountBox,
  Person,
  Build,
  People,
  RateReview,
  Dashboard,
  AddToQueue,
  Home,
  Assignment,
  Call,
  PhoneAndroid,
  MobileFriendly,
  Tv,
  Queue,
  ListAlt,
  Schedule,
  Apartment,
  ShoppingCart,
  NewReleases,
  SettingsInputComponent,
  Notifications, Textsms
} from '@material-ui/icons';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Calculate, Science, Terminal, ShowChart, Newspaper} from "@mui/icons-material";
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from "@material-ui/icons/Home";

let cls = {className: 'nav-item-icon'};

const routesConfig = [
  {
    id: 'main_menu',
    title: 'Меню',
    messageId: 'ne.sidebar.main',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Главная страница',
        messageId: 'ne.dashboard.title',
        type: 'item',
        iconElement: <HomeIcon  {...cls} />,
        url: '/dashboard',
        auth: authRole.user,
      },
      {
        id: 'main',
        title: 'Дашборд',
        messageId: 'ne.main.title',
        type: 'item',
        iconElement: <Dashboard {...cls} />,
        url: '/main',
        auth: authRole.boss,
      },
      {
        id: 'moderation',
        title: 'Модерация',
        messageId: 'ne.moderation.title',
        type: 'item',
        iconElement: <RateReview {...cls} />,
        url: '/moderation',
        auth: authRole.moderator,
      },
      {
        id: 'statistics',
        title: 'Статистика',
        messageId: 'ne.manage.title',
        type: 'collapse',
        iconElement: <ShowChart {...cls} />,
        auth: authRole.boss,
        children: [
          {
            id: 'mobile-app-stat',
            title: 'Статистика мобильного приложения',
            type: 'item',
            iconElement: <MobileFriendly {...cls} />,
            url: '/mobile-app-stat',
            auth: authRole.boss,
          },
          {
            id: 'terminals-stat',
            title: 'Статистика терминалов',
            type: 'item',
            iconElement: <BarChartIcon {...cls} />,
            url: '/terminals-stat',
            auth: authRole.boss,
          },
        ]
      },
      {
        id: 'manage',
        title: 'Управление',
        messageId: 'ne.manage.title',
        type: 'collapse',
        iconElement: <Build {...cls} />,
        auth: authRole.boss,
        children: [
          {
            id: 'users',
            title: 'Пользователи',
            messageId: 'ne.users',
            type: 'item',
            iconElement: <People {...cls} />,
            url: '/users',
            auth: authRole.boss,
          },
          {
            id: 'addresses',
            title: 'Помещения',
            messageId: 'ne.addresses',
            type: 'item',
            iconElement: <Apartment {...cls} />,
            url: '/address',
            auth: authRole.admin,
          },
          {
            id: 'profosmotr',
            title: 'Калькулятор профосмотра',
            messageId: 'ne.profosmotr',
            type: 'item',
            iconElement: <Calculate {...cls} />,
            url: '/profosmotr',
            auth: authRole.admin,
          },
          {
            id: 'rubricators',
            title: 'Рубрикатор лаборатории',
            messageId: 'ne.rubricators',
            type: 'item',
            iconElement: <Science {...cls} />,
            url: '/rubricator',
            auth: authRole.admin,
          },
          {
            id: 'monitors',
            title: 'Мониторы',
            messageId: 'ne.monitors',
            type: 'item',
            iconElement: <Tv  {...cls} />,
            url: '/monitors',
            auth: authRole.admin,
          },
          {
            id: 'rubricator-calc',
            title: 'Калькулятор лаборатории',
            messageId: 'ne.rubricator.calc',
            type: 'item',
            iconElement: <Calculate {...cls} />,
            url: '/rubricator-calc',
            auth: authRole.admin,
          },
          {
            id: 'giveDropTemplates',
            title: 'Шаблоны приёма и выдачи',
            messageId: 'ne.giveDropTemplates',
            type: 'item',
            iconElement: <Build {...cls} />,
            url: '/place-templates',
            auth: authRole.boss,
          },
          {
            id: 'editRegistries',
            title: 'Эл.очередь',
            messageId: 'ne.editRegistries',
            type: 'item',
            iconElement: <Queue {...cls} />,
            url: '/edit-registries',
            auth: authRole.boss,
          },
          {
            id: 'editExaminations',
            title: 'Эл.медосмотр',
            messageId: 'ne.editExaminations',
            type: 'item',
            iconElement: <Assignment {...cls} />,
            url: '/edit-examinations',
            auth: authRole.admin,
          },
          {
            id: 'calls',
            title: 'Звонки и СМС',
            messageId: 'ne.calls',
            type: 'item',
            iconElement: <Call {...cls} />,
            url: '/calls',
            auth: authRole.admin,
          },
        ],
      },
      {
        id: 'devices',
        title: 'Устройства',
        messageId: 'ne.manage.title',
        type: 'collapse',
        iconElement: <SettingsInputComponent {...cls} />,
        auth: authRole.admin,
        children: [
          {
            id: 'news',
            title: 'Новости',
            messageId: 'ne.news',
            type: 'item',
            iconElement: <Newspaper {...cls} />,
            url: '/news',
            auth: authRole.admin,
          },
          {
            id: 'terminals',
            title: 'Терминалы самообслуживания',
            messageId: 'ne.terminals',
            type: 'item',
            iconElement: <Terminal {...cls} />,
            url: '/terminals',
            auth: authRole.admin,
          },
          {
            id: 'lk',
            title: 'Личный кабинет',
            type: 'item',
            iconElement: <Person {...cls} />,
            url: '/lk/settings',
            auth: authRole.admin,
          },
        ]
      },
      {
        id: 'notification',
        title: 'Уведомления',
        messageId: 'ne.notification.title',
        type: 'item',
        iconElement: <Textsms {...cls} />,
        url: '/notification',
        auth: authRole.user,
      },
      {
        id: 'profile',
        title: 'Личный кабинет',
        messageId: 'ne.profile.title',
        type: 'item',
        iconElement: <AccountBox {...cls} />,
        url: '/profile',
        auth: authRole.user,
      },
      {
        id: 'orders',
        title: 'Заказы',
        messageId: 'ne.orders.title',
        type: 'item',
        iconElement: <ShoppingCart {...cls} />,
        url: '/orders',
        auth: [authRole.admin, 'patients_access'],
        count: RIS._me?.count_new_orders,
      },
      {
        id: 'new-services',
        title: 'Новые услуги',
        messageId: 'ne.newServices',
        type: 'item',
        iconElement: <NewReleases {...cls} />,
        url: '/new-services',
        auth: authRole.user,
      },
    ],
  } ,
];
export default routesConfig;
