import Centrifuge from 'centrifuge';
import {useContext, useEffect, useState} from 'react';
import AppContext from './AppContext';
import {inviteNextExaminationTicket} from "../../ris/examinations/detail/index";
import {formatDuration, isDefinedAndNotEmpty} from "../../ris/util/helper";


window.addEventListener('storage', (event) => {
  if (event.key === 'lastInvitationTime') {
    console.error('Другая вкладка вызвала inviteNextExaminationTicket');
  }
});

const WebSocketConnect = () => {
  // Использование useContext для получения user и setUser из AppContext
  let {user, setUser, count_new_orders, setCountNewOrders} = useContext(AppContext);
  if (!user) user = RIS._me;

  // Инициализация состояния для управления подписками
  const [subscriptions, setSubscriptions] = useState({});

  useEffect(() => {
    // Установка токена для Centrifuge, если jwt доступен
    if (user && user.jwt) {
      const centrifuge = new Centrifuge(Api.socketSchema() + Api.domain() + Api.socketPath(),);
      centrifuge.setToken(user.jwt);

      const reSetNextTicket = () => {
        let t = null;
        setTimeout(function () {
          if (RIS._me.my_queue && RIS._me.my_directions && RIS._me.all_tickets) {
            let flag_find_next_ticket = false;
            RIS._me.all_tickets.map((ticket) =>
              Object.values(RIS._me.my_directions).map((dir) => {
                let ticketLetter = ticket.code.split('')[0].toLowerCase();
                let dirLetter = dir.letter.toLowerCase();
                if (ticketLetter === dirLetter && !flag_find_next_ticket) {
                  t = ticket;
                  flag_find_next_ticket = true;
                }
              }),
            );
          }
          RIS._me.next_ticket = t;
          setUser(RIS._me);
        }, 1000);
      };

      // Определение функций для подписки на каналы и действий при получении сообщений
      const setupSubscriptions = () => {
        const newSubscriptions = {
          subscriptionNewOrders: centrifuge.subscribe('count-new-orders', function (ctx) {
            setCountNewOrders(ctx.data.count); // Обновляем состояние count_new_orders в контексте
          }),

          subscriptionTickets: centrifuge.subscribe(
            'tickets-chat',
            function (ctx) {
              // if (ctx.data.ticket_process.ticket?.code) {
              // const now = new Date();
              // const currentTime = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`; // Получение текущего времени с точностью до миллисекунд
              // console.log(`[${currentTime}] SOUND OF TICKET: ${ctx.data.ticket_process.ticket.code}, ctx.data:`, ctx.data);
              // }

              // console.log('ctx.data',ctx.data);
              if(ctx.data.registry_id == 'f7dcb93e-5932-455a-b06b-b6d66674d414'){
                console.log('даешь голос!',ctx.data.ticket_process.ticket)
              }
              if (user.current_registry?.id === ctx.data.registry_id) {
                if (!ctx.data.small_queue_id || ctx.data.small_queue_id === RIS._me.my_queue.uuid) {
                  RIS._me.all_tickets = ctx.data.all_queue;
                }
                if (RIS._me.is_new_ticket === false && !RIS._me.current_ticket) {
                  RIS._me.is_new_ticket = true;
                }
                if (user.id === ctx.data.ticket_process.user_id) {
                  RIS._me.current_ticket = ctx.data.ticket_process.ticket;
                }
                reSetNextTicket();
              }
            },
          ),
          subscriptionWindows: centrifuge.subscribe(
            'windows-chat',
            function (ctx) {
              if (user.current_registry?.id === ctx.data.registry_id) {
                RIS._me.available_windows = ctx.data.windows;
                RIS._me.busy_windows = ctx.data.busy_windows;
                setUser(RIS._me);
              }
            },
          ),
          subscriptionExaminationMonitor: centrifuge.subscribe(
            'examination-monitor',
            function (ctx) {
              console.log('examination-monitor', ctx);
            },
          ),
          subscriptionDirections: centrifuge.subscribe(
            'directions-chat',
            function (ctx) {
              if (user.current_registry?.id === ctx.data.registry_id) {
                RIS._me.unused_directions = ctx.data.directions;
                setUser(RIS._me);
                reSetNextTicket();
              }
            },
          ),
          subscriptionRegistrators: centrifuge.subscribe(
            'registrators-chat',
            function (ctx) {
              if (user.current_registry?.id === ctx.data.registry_id) {
                RIS._me.registrators_count = ctx.data.registrators.length;
                setUser(RIS._me);
              }
            },
          ),
          subscriptionTicketStats: centrifuge.subscribe(
            'ticket-stats-chat',
            function (ctx) {
              if (user.current_registry?.id === ctx.data.registry_id) {
                RIS._me.today_tickets = ctx.data.all;
                if (user.id === ctx.data.user_id) {
                  RIS._me.today_my_tickets = ctx.data.my;
                  RIS._me.my_average_time = ctx.data.average;
                }
                setUser(RIS._me);
              }
            },
          ),

          subscriptionParking: centrifuge.subscribe(
            'parking',
            function (ctx) {
              RIS._me.parking = ctx?.data ?? [];
              setUser(RIS._me);
            },
          ),

          // TODO Переделать чтобы слушалось только у того кто открыл страницу с дашбордом!!!
          subscriptionExaminationDashboard: centrifuge.subscribe(
            'examination-dashboard',
            function (ctx) {
              if (RIS._me.examination_dashboard !== undefined) {
                RIS._me.examination_dashboard.examination_id = ctx.data.examination_id;
                RIS._me.examination_dashboard.all_today_lost = ctx.data.all_today_lost; // Сегодняшние потеряшки
                RIS._me.examination_dashboard.all_today_process_wait = ctx.data.all_today_process_wait; // Вызванные в кабинет талоны
                RIS._me.examination_dashboard.all_today_done = ctx.data.all_today_done;
                RIS._me.examination_dashboard.all_today_waiting = ctx.data.all_today_waiting;
                RIS._me.examination_dashboard.all_avg_waiting_time = formatDuration(ctx.data.all_avg_waiting_time);
                RIS._me.examination_dashboard.today_average_receipt_time = formatDuration(ctx.data.today_average_receipt_time);
                RIS._me.examination_dashboard.summary_today_waiting_time = formatDuration(ctx.data.summary_today_waiting_time, true);
                RIS._me.examination_dashboard.queues = ctx.data.queues;
                setUser(RIS._me);
              }
              // Цепляем в этом месте изменение списка потеряшек для регистратуры
              if(isDefinedAndNotEmpty(RIS._me.related_examinations_for_current_registry) && RIS._me.related_examinations_for_current_registry?.[0]?.examination?.id === ctx.data.examination_id) {
                RIS._me.lost_examination_tickets_for_current_registry = ctx.data.all_today_lost;
                setUser(RIS._me);
              }
            },
          ),

          subscriptionExaminationTickets: centrifuge.subscribe(
            'mo-tickets-channel/' + RIS._me.id,
            function (ctx) {
              if (user.current_examination?.id === ctx.data.examination_id) {
                RIS._me.my_process_process_examination_ticket = ctx.data.my_process_process_examination_ticket; // тикет, который пришел
                RIS._me.my_process_wait_examination_ticket = ctx.data.my_process_wait_examination_ticket; // тикет, который вызвали
                RIS._me.my_wait_wait_examination_ticket = ctx.data.my_wait_wait_examination_ticket;       // тикет, который следующий
                RIS._me.lost_examination_tickets = ctx.data.lost_examination_tickets;       // потеряшки
                RIS._me.my_tickets_future = ctx.data.examination.myTicketsFuture; // Тикеты которые еще должны пройти кабинет
                RIS._me.all_examination_tickets = ctx.data.examination.todayTicketsProcess;
                RIS._me.my_tickets_done = ctx.data.examination.myTicketsDone;
                RIS._me.my_examination_tickets = ctx.data.my_queue;
                RIS._me.other_examination_tickets = ctx.data.other_queue;
                setUser(RIS._me);

                // сомнительное место так как RIS._me.active_examination_queue может быть не задано в момент получения сообщения по сокету
                const foundAvgReceptionTime = ctx.data.dop_info.queue_info.find(item => item.office_uuid === RIS._me.active_examination_queue?.office_id);
                if (foundAvgReceptionTime) {
                  RIS._me.my_dop_info = formatDuration(foundAvgReceptionTime.average_duration);
                  RIS._me.my_reception_count = foundAvgReceptionTime.reception_count;
                }

                RIS._me.all_today_done = ctx.data.dop_info.all_today_done;
                RIS._me.all_today_waiting = ctx.data.dop_info.all_today_waiting;
                RIS._me.all_avg_waiting_time = formatDuration(ctx.data.dop_info.all_avg_waiting_time);
                setUser(RIS._me);
              }
            },
          ),
        };
        // Сохраняем все подписки в состояние
        setSubscriptions(newSubscriptions);
      }
      // Вызов функции настройки подписок
      setupSubscriptions();

      centrifuge.connect();

      // Функция для отписки
      const unsubscribeAll = () => {
        Object.values(subscriptions).forEach(subscription => subscription.unsubscribe());
      };

      // Отписка при размонтировании
      return () => {
        unsubscribeAll();
      };
    }
  }, [user, setUser, setCountNewOrders]); // Переустановка подписок при изменении user
  return null;
};

export default WebSocketConnect;